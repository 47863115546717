<template>
  <ShortcutTooltip content="Afficher les filtres" :shortcuts="['f']">
    <FilterButton :class="{ isActive }" @click="emit('onFilter')">
      <FilterIcon :icon="mapIcon.filter" />
      Filtrer
      <CountIndicator v-if="count" :count="count" />
    </FilterButton>
  </ShortcutTooltip>
</template>

<script setup lang="ts">
import type { Props as CountIndicatorProps } from "~/components/ui/count-indicator/CountIndicator.vue";

type Props = CountIndicatorProps & { isActive?: boolean };

type Emits = { onFilter: [] };

defineProps<Props>();

const emit = defineEmits<Emits>();
</script>

<style scoped lang="scss">
.isActive {
  outline: 2px solid var(--blue);
  outline-offset: 2px;
}
</style>
